.bulletList {
  list-style: none;
  margin-bottom: auto;
  padding: 0;
}

.bulletList li {
  display: flex;
  flex-direction: row;
  gap: var(--space-1);
  align-items: center;
  margin-bottom: var(--space-2);
}

.numberList {
  margin: var(--space-5) 0;
  padding: 0;
  list-style: none;
}

.numberList li {
  display: flex;
  flex-direction: row;
  gap: var(--space-1);
  align-items: center;
  margin-bottom: var(--space-2);
  counter-increment: item;
}

.numberList li:before {
  margin-right: var(--space-1);
  content: counter(item);
  background: var(--color-static-main);
  border-radius: 100%;
  color: white;
  width: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 18px;
  text-align: center;
  flex-shrink: 0;
}

.marquee {
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--space-6);
  background-color: var(--color-static-main);
  color: white;
  padding: 20px;
  margin: var(--space-3) calc(-1 * var(--space-9)) calc(-1 * var(--space-3));
}

.marqueeContent {
  margin: 0;
  padding: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  min-width: 100%;
  gap: var(--space-6);
  animation: scroll 10s linear infinite;
}

.marqueeItem {
  font-size: 18px;
  display: flex;
  align-items: center;
}

.marqueeItem img {
  margin: 0 var(--space-1);
}

.content {
  background: linear-gradient(-90deg, #10ff84, #5fddff);
  background-size: 200% 200%;
  animation: gradient 15s ease infinite;
  border-radius: 6px;
  padding: var(--space-9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--space-2);
  width: 100%;
  height: 100%;
}

.checkIcon {
  color: var(--color-static-main);
}

.button {
  color: var(--color-static-main);
}

@media (max-width: 899.95px) {
  .content {
    padding: var(--space-6);
  }

  .marquee {
    margin: var(--space-3) calc(-1 * var(--space-6)) calc(-1 * var(--space-3));
  }
}

@media (max-width: 599.95px) {
  .content {
    padding: var(--space-4);
  }

  .marquee {
    margin: var(--space-3) calc(-1 * var(--space-4)) 0;
  }
}

@keyframes gradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--space-6)));
  }
}
