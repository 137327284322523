.loginCard {
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  padding: var(--space-2);
}

.loginContent {
  display: flex;
  height: 100%;
  width: 320px;
  gap: var(--space-1);
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
